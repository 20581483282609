import React, { useEffect, useState } from 'react';
import { baseurl } from '../App';
import axios from 'axios';

export default function ApproveUsers() {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = () => {
    axios.get(`${baseurl}/getusers`)
      .then((response) => {
        const unapprovedUsers = response.data.filter(user => user.status === 'unapprroved');
        setUsers(unapprovedUsers);
      })
      .catch((err) => console.log(err));
  };

  const updateUserStatus = async (item, status) => {
    try {
      await axios.put(`${baseurl}/putusers`, { 
        ...item, 
        status: status 
      });
      fetchUsers();
    } catch (error) {
      console.error('There was an error updating the user status!', error);
    }
  };

  return (
    <>
      <table className="table table-bordered border-dark w-100 mt-4 mx-auto">
        <thead>
          <tr>
            <th scope='col'>Id</th>
            <th scope="col">Username</th>
            <th scope='col'>Dob</th>
            <th scope="col">Phone</th>
            <th scope="col">Transaction ID</th>
            <th scope="col">Status</th>
            <th scope="col">Option</th>
          </tr>
        </thead>
        <tbody>
          {users.map((item, index) => (
            <tr key={index} className="text-center">
              <th scope="row">{item.id}</th>
              <th scope="row">{item.name}</th>
              <th>{item.dob}</th>
              <th>{item.phone}</th>
              <th>{item.transactionId}</th>
              <th style={{ color: item.status === 'blocked' ? 'red' : 'green' }}>
                {item.status}
              </th>
              <th className='d-flex'>
                <button
                  className="btn btn-success m-2 col"
                  onClick={() => updateUserStatus(item, 'pending')}
                >
                  Approve
                </button>
                <button
                  className="btn btn-danger m-2 col"
                  onClick={() => updateUserStatus(item, 'blocked')}
                >
                  Unapprove
                </button>
              </th>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}
