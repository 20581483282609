import React, { useContext, useState } from 'react';
import { Usercontext } from '../Usercontext';
import { baseurl } from '../App';
import profile from "../Assets/profile.jpg"
export default function Profile() {
  const { user, setuser } = useContext(Usercontext);
  const [id] = useState(user.id);
  const [name, setName] = useState(user.name);
  const [email, setEmail] = useState(user.email);
  const [password, setPassword] = useState(user.password);
  const [mobileNo, setMobileNo] = useState(user.phone);
  const [status] = useState(user.status);
  const [address, setAddress] = useState(user.address);
  const [photo, setPhoto] = useState(user.photo);
  const [signature, setSignature] = useState(user.signature);
  const [bloodGroup, setBloodGroup] = useState(user.bloodgroup);
  const [batch, setBatch] = useState(user.batch);
  const [hostel, setHostel] = useState(user.hostel);
  const [dob, setDob] = useState(user.dob);

  const handleImage = (e) => {
    let file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setPhoto(reader.result);
    };
  };

  const handleSignature = (e) => {
    let file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setSignature(reader.result);
    };
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${baseurl}/putusers`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          id:id,
          name: name,
          email: email,
          password: password,
          phone: mobileNo,
          status: status,
          address: address,
          photo: photo,
          signature: signature,
          bloodgroup: bloodGroup,
          batch: batch,
          hostel: hostel,
          dob: dob
        })
      });
      const data = await response.json();
      setuser(data);
      alert('Updated');
    } catch (error) {
      alert('Error sending data:', error);
    }
  };

  return (
    <div className='mainpage d-flex justify-content-center '>
      <form className='maindivs justify-content-center card p-3'>
      <img src={user.photo ? photo : profile} className='prof m-3 mx-auto' alt='Profile not found' />
      <label className='form-label'>Change Profile Image</label>
      <input
        type='file'
        name='Profile Image'
        className='form-control mb-3 mx-auto'
        onChange={(e) => handleImage(e)}
      />
      <label className='form-label'>Change Signature</label>
      <input
        type='file'
        name='Signature Image'
        className='form-control mb-3 mx-auto'
        onChange={(e) => handleSignature(e)}
      />
      <input
        value={name}
        onChange={(e) => setName(e.target.value)}
        placeholder='Name'
        className='form-control mb-3'
      />
      <input
        value={email}
        type='email'
        onChange={(e) => setEmail(e.target.value)}
        placeholder='Email'
        className='form-control mb-3'
      />
      <input
        value={mobileNo}
        onChange={(e) => setMobileNo(e.target.value)}
        placeholder='Mobile Number'
        className='form-control mb-3'
      />
        <input
          value={dob}
          onChange={(e) => setDob(e.target.value)}
          placeholder='Date of Birth'
          className='form-control mb-3'
        />
      <input
        value={address}
        onChange={(e) => setAddress(e.target.value)}
        placeholder='Address'
        className='form-control mb-3'
      />
      <input
        value={bloodGroup}
        onChange={(e) => setBloodGroup(e.target.value)}
        placeholder='Blood Group'
        className='form-control mb-3'
      />
      <input
        value={batch}
        onChange={(e) => setBatch(e.target.value)}
        placeholder='Batch'
        className='form-control mb-3'
      />
      <input
        value={hostel}
        onChange={(e) => setHostel(e.target.value)}
        placeholder='Hostel'
        className='form-control mb-3'
      />
      <input
        type='password'
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        placeholder='Password'
        className='form-control mb-3'
      />
      <button className='btn btn-primary' onClick={(e) => handleUpdate(e)}>
        Update Profile
      </button>
      </form>
    </div>
  );
}
