import React, { useEffect, useState } from 'react';
import "../Styles/News.css";
import { baseurl } from '../App';
import axios from 'axios';

export default function News() {
  const [news, setnews] = useState([]);

  useEffect(() => {
    fetchannouncements();
  }, []);

  const fetchannouncements = async () => {
    try {
      const response = await axios.get(`${baseurl}/getannouncements`);
      setnews(response.data);
    } catch (err) {
      console.error(err);
    }
  };

  const downloadDocument = async (url) => {
    try {
      const response = await axios.get(url, { responseType: 'blob' });
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'document.pdf'; // You can set the file name dynamically if needed
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(link.href);
    } catch (err) {
      console.error('Error downloading the document:', err);
    }
  };

  return (
    <div className='newsmain'>
      <h5 className='news-head'>Announcements</h5>
      {news.map((item) => (
        <marquee className="news-links" key={item.id}>
          <p className='news-discript' onClick={() => downloadDocument(item.document)}>{item.description}</p>
        </marquee>
      ))}
    </div>
  );
}
