import React, { useState } from 'react';
import '../Styles/Dmain.css';
import du from '../Assets/du.png';
import News from '../Dashboard/News';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { TiThMenu } from "react-icons/ti";

function Umain() {
  const location = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <>
      <div className='header'>
        <div className='nav-links'>
          <NavLink to="" text="Home" currentPath={location.pathname} />
          <NavLink to="/user/about" text="About Us" currentPath={location.pathname} />
          <NavLink to="/user/committe" text="Commitee" currentPath={location.pathname} />
          <NavLink to="/user/profile" text="Profile" currentPath={location.pathname} />
          <NavLink to="/user/id" text="Get Id Card" currentPath={location.pathname} />
          <NavLink to="/user/gallery" text="Gallery" currentPath={location.pathname} />
          <NavLink to="/" text="Log Out" currentPath={location.pathname} />
        </div>
        <img src={du} className='duimage' alt='bsmlogo' />
        <TiThMenu className='text-light three-dots' onClick={toggleMenu} />
      </div>
      {menuOpen && (
        <div className='mobile-menu'>
          <NavLink to="" text="Home" currentPath={location.pathname} />
          <NavLink to="/user/about" text="About Us" currentPath={location.pathname} />
          <NavLink to="/user/committe" text="Commitee" currentPath={location.pathname} />
          <NavLink to="/user/profile" text="Profile" currentPath={location.pathname} />
          <NavLink to="/user/id" text="Get Id Card" currentPath={location.pathname} />
          <NavLink to="/user/gallery" text="Gallery" currentPath={location.pathname} />
          <NavLink to="/" text="Log Out" currentPath={location.pathname} />
        </div>
      )}
      <News />
      <Outlet />
    </>
  );
}

function NavLink({ to, text, currentPath }) {
  const isActive = currentPath === to;
  return (
    <Link to={to} className={`headbutton ${isActive ? 'active' : ''}`}>
      {text}
    </Link>
  );
}

export default Umain;
