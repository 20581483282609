import React, { useState } from 'react';
import { baseurl } from '../App';

export default function ForgotPassword() {
  const [emailOrPhone, setEmailOrPhone] = useState('');
  const [otp, setOtp] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [step, setStep] = useState(1);
  const [message, setMessage] = useState('');
  const [errors, setErrors] = useState({});

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (step === 1) {
      if (!emailOrPhone) {
        setErrors({ emailOrPhone: 'Email or Phone is required' });
        return;
      }

      try {
        const response = await fetch(`${baseurl}/send-otp`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ emailOrPhone }),
        });

        if (response.ok) {
          setStep(2);
          setMessage('OTP sent to your email.');
        } else {
          setErrors({ emailOrPhone: 'Failed to send OTP. Please check the email/phone.' });
        }
      } catch (error) {
        console.error('Error:', error);
        setErrors({ emailOrPhone: 'An error occurred. Please try again.' });
      }
    } else if (step === 2) {
      if (!otp || !newPassword) {
        setErrors({ otp: 'OTP is required', newPassword: 'New Password is required' });
        return;
      }

      try {
        const response = await fetch(`${baseurl}/verify-otp`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ emailOrPhone, otp, newPassword }),
        });

        if (response.ok) {
          setMessage('Password updated successfully.');
          setStep(1); 
        } else {
          setErrors({ otp: 'Invalid OTP or unable to update password.' });
        }
      } catch (error) {
        console.error('Error:', error);
        setErrors({ otp: 'An error occurred. Please try again.' });
      }
    }
  };

  return (
    <div className='mainpage d-flex mt-5 justify-content-center'>
      <form className='maindivs' onSubmit={handleSubmit}>
        <h2 className='m-2 text-center mb-3'>{step === 1 ? 'Forgot Password' : 'Verify OTP'}</h2>
        {message && <div className="text-success fw-bolder text-center mb-2">{message}</div>}
        
        {step === 1 && (
          <div className='form-group'>
            <label className='label label-default mb-2'><strong>Email or Phone</strong></label>
            <input
              className='form-control mb-2 mx-auto'
              type='text'
              value={emailOrPhone}
              onChange={(e) => setEmailOrPhone(e.target.value)}
              required
            />
            {errors.emailOrPhone && <div className="text-danger fw-bolder">{errors.emailOrPhone}</div>}
          </div>
        )}

        {step === 2 && (
          <>
            <div className='form-group'>
              <label className='label label-default mb-2'><strong>OTP</strong></label>
              <input
                className='form-control mb-2 mx-auto'
                type='text'
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                required
              />
              {errors.otp && <div className="text-danger">{errors.otp}</div>}
            </div>
            <div className='form-group'>
              <label className='label label-default mb-2'><strong>New Password</strong></label>
              <input
                className='form-control mb-2 mx-auto'
                type='password'
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
              />
              {errors.newPassword && <div className="text-danger fw-bolder">{errors.newPassword}</div>}
            </div>
          </>
        )}

        <div className="text-center">
          <button className='btn btn-primary w-50 m-3' type='submit'>
            {step === 1 ? 'Send OTP' : 'Verify and Reset Password'}
          </button>
        </div>
      </form>
    </div>
  );
}
