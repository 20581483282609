import React, { useEffect, useState } from 'react';
import '../Styles/Manage.css';
import { baseurl } from '../App';
import axios from 'axios';
import { toast } from 'react-toastify';

export default function Managenews() {
  const [announcements, setAnnouncements] = useState([]);
  const [description, setDescription] = useState("");
  const [id, setId] = useState();
  const [document, setDocument] = useState("");
  const [edit, setEdit] = useState(false);

  const handleDelete = () => {
    axios
      .delete(baseurl + `/deleteannouncments/${id}`)
      .then((res) => {
        toast.success(res.data);
        fetchAnnouncements();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (edit) {
      axios
        .put(baseurl + `/putannouncements`, { id, description, document })
        .then((res) => {
          toast.success(res.data);
          fetchAnnouncements();
          clearAll();
          setEdit(false);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      axios
        .post(baseurl + `/postannouncements`, { description, document })
        .then((res) => {
          toast.success(res.data);
          fetchAnnouncements();
          clearAll();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const clearAll = () => {
    setDescription("");
    setDocument("");
  };

  const handleDocument = (e) => {
    let file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setDocument(reader.result);
    };
  };

  useEffect(() => {
    fetchAnnouncements();
  }, []);

  const fetchAnnouncements = () => {
    axios.get(baseurl + `/getannouncements`)
      .then((response) => {
        setAnnouncements(response.data);
      })
      .catch((err) => console.log(err));
  };
  const openDocument = (url) => {
    window.open(url, '_blank');
  };
  return (
    <div className='news-main'>
      <form className='card m-3 w-25 p-3' onSubmit={handleSubmit}>
        <h5 className='mb-2 text-center'>Add Announcements</h5>
        <input type="text" className="form-control mb-2" value={description} onChange={(e) => { setDescription(e.target.value) }} placeholder="Description" />
        <input type='file' className='form-control mb-2' onChange={handleDocument} />
        <button type='submit' className='btn btn-primary mb-2'>{edit ? "Edit" : "Add"}</button>
      </form>
      {announcements.map((item) => {
        return (
          <div className='card m-3 w-25 p-3' key={item.id}>
            <h5 className='mb-3 text-center'>{item.description}</h5>
            <p className='fs-6 mb-3'>{item.description}</p>
            <button className='btn btn-dark' onClick={() => openDocument(item.document)}>View Document</button>
            <div className='row'>
              <button className='col w-50 m-2 btn btn-warning mb-2' onClick={() => {
                setEdit(true);
                setId(item.id);
                setDescription(item.description);
                setDocument(item.document);
              }}>Edit</button>
              <button type="submit" className='col m-2 w-50 btn btn-danger' onClick={() => { setId(item.id); handleDelete(); }}>Delete</button>
            </div>
          </div>
        )
      })}
    </div>
  )
}
