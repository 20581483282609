import React from 'react'
import p1 from "../Assets/p1.jpg"
import p2 from "../Assets/p2.jpg"
import p3 from "../Assets/p3.jpg"
import p4 from "../Assets/p4.jpg"
import p5 from "../Assets/p5.jpg"
import p6 from "../Assets/p6.jpg"
import p7 from "../Assets/p7.jpg"
export default function Committe() {
  return (
   <div className='mainpage'>
    <img src={p1} alt="committe" className='pdf-img'/>
    <img src={p2} alt="committe" className='pdf-img'/>
    <img src={p3} alt="committe" className='pdf-img'/>
    <img src={p4} alt="committe" className='pdf-img'/>
    <img src={p5} alt="committe" className='pdf-img'/>
    <img src={p6} alt="committe" className='pdf-img'/>
    <img src={p7} alt="committe" className='pdf-img'/>
   </div>
  )
}
