import React, { useEffect, useState } from 'react';
import { baseurl } from '../App';
import axios from 'axios';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
export default function ManageUsers() {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = () => {
    axios.get(`${baseurl}/getusers`)
      .then((response) => {
        setUsers(response.data);
      }) 
      .catch((err) => console.log(err));
  };

  const updateUserStatus = async (item, status) => {
    try {
      await axios.put(`${baseurl}/putusers`, {
        id: item.id,
        name: item.name,
        password: item.password,
        dob: item.dob,
        qualification: item.qualification,
        hostel: item.hostel,
        batch: item.batch,
        address: item.address,
        email: item.email,
        phone: item.phone,
        signature: item.signature,
        bloodgroup: item.bloodgroup,
        status: status,
      });
      fetchUsers();
    } catch (error) {
      console.error('There was an error updating the user status!', error);
    }
  };


  const generatePDF = () => {
    const doc = new jsPDF();

    const columns = ['ID', 'Username', 'DOB', 'Hostel', 'Batch', 'Email', 'Phone'];
    const rows = users.map((item) => [
      item.id,
      item.name,
      item.dob,
      item.hostel,
      item.batch,
      item.email,
      item.phone,
    ]);

    doc.autoTable({
      head: [columns],
      body: rows,
    });

    doc.save('users_table.pdf');
  };

  return (
    <>
      <div className="d-flex justify-content-center">
        <button className="btn btn-primary mt-4" onClick={generatePDF}>
          Generate PDF
        </button>
      </div>

      <table className="table table-bordered border-dark w-100 mt-4 mx-auto">
        <thead>
          <tr>
            <th scope='col'>Id</th>
            <th scope="col">Username</th>
            <th scope='col'>Dob</th>
            <th scope='col'>Hostel</th>
            <th scope='col'>Batch</th>
            <th scope='col'>Address</th>
            <th scope="col">E-mail</th>
            <th scope="col">Phone</th>
            <th scope="col">Status</th>
            <th scope="col">Option</th>
          </tr>
        </thead>
        <tbody>
          {users.map((item, index) => (
            <tr key={index} className="text-center">
              <th scope="row">{item.id}</th>
              <th scope="row">{item.name}</th>
              <th>{item.dob}</th>
              <th>{item.hostel}</th>
              <th>{item.batch}</th>
              <th>{item.address}</th>
              <th>{item.email}</th>
              <th>{item.phone}</th>
              <th style={{ color: item.status === 'blocked' ? 'red' : 'green' }}>
                {item.status}
              </th>
              <th className='d-flex'>
                <button
                  className="btn btn-success m-2 col"
                  onClick={() => updateUserStatus(item, 'pending')}
                >
                  Unblock
                </button>
                <button
                  className="btn btn-danger m-2 col"
                  onClick={() => updateUserStatus(item, 'blocked')}
                >
                  Block
                </button>
              </th>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}
