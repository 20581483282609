import React, { useContext, useState } from 'react';
import "../Styles/up.css";
import { useNavigate } from 'react-router-dom';
import { Usercontext } from '../Usercontext';
import { ToastContainer, toast } from 'react-toastify';
import { baseurl } from '../App';

export default function Updatepassword() {
  let navigate = useNavigate();
  const { user } = useContext(Usercontext);
  const [password, setPassword] = useState('');
  const [cPassword, setCPassword] = useState('');

  const update = async () => {
    if (password === '' || cPassword === '') {
      toast.error('Fill all the inputs', { theme: 'colored' });
    } else if (password !== cPassword) {
      toast.error('Passwords must match', { theme: 'colored' });
    } else {
      try {
        const response = await fetch(`${baseurl}/putadmin`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            id: user.id,
            name: user.name,
            password: password,
          }),
        });

        if (response.ok) {
          alert('Password changed', { theme: 'colored' });
          navigate(-1);
        } else {
          toast.error('Failed to update password', { theme: 'colored' });
        }
      } catch (error) {
        toast.error('An error occurred', { theme: 'colored' });
      }
    }
  };

  return (
    <div>
      <div className='backscreen' onClick={() => navigate(-1)}></div>
      <div className='main-up'>
        <h3>Update Password</h3>
        <div className='form-group'>
          <label><strong>New Password</strong></label>
          <input
            className='form-control mb-2 mx-auto'
            type='password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className='form-group'>
          <label><strong>Confirm Password</strong></label>
          <input
            className='form-control mb-2 mx-auto'
            type='password'
            value={cPassword}
            onChange={(e) => setCPassword(e.target.value)}
          />
        </div>
        <button
          className='btn btn-primary blocked w-100 mt-2'
          onClick={update}
        >
          Change Password
        </button>
      </div>
      <ToastContainer />
    </div>
  );
}
