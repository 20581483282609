import React, { useState } from 'react';
import '../Styles/Dmain.css';
import du from '../Assets/du.png';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { TiThMenu } from "react-icons/ti";

function Amain() {
  const location = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <>
      <div className='header'>
        <div className='nav-links'>
          <NavLink to="/amain/" text="Manage Users" currentPath={location.pathname} />
          <NavLink to="/amain/approve" text="Approve Users" currentPath={location.pathname} />
          <NavLink to="/amain/mgallery" text="Manage Gallery" currentPath={location.pathname} />
          <NavLink to="/amain/mnews" text="Manage Announcements" currentPath={location.pathname} />
          <NavLink to="/amain/updatepassword" text="Update Password" currentPath={location.pathname} />
          <NavLink to="/" text="Log out" currentPath={location.pathname} />
        </div>
        <img src={du} className='duimage' alt='bsmlogo' />
        <TiThMenu className='text-light three-dots' onClick={toggleMenu} />
      </div>
      {menuOpen && (
        <div className='mobile-menu' onClick={toggleMenu}>
          <NavLink to="/amain/" text="Manage Users" currentPath={location.pathname} />
          <NavLink to="/amain/approve" text="Approve Users" currentPath={location.pathname} />
          <NavLink to="/amain/mgallery" text="Manage Gallery" currentPath={location.pathname} />
          <NavLink to="/amain/mnews" text="Manage Announcements" currentPath={location.pathname} />
          <NavLink to="/amain/updatepassword" text="Update Password" currentPath={location.pathname} />
          <NavLink to="/" text="Log out" currentPath={location.pathname} />
        </div>
      )}
      <Outlet />
    </>
  );
}

export default Amain;

function NavLink({ to, text, currentPath }) {
  const isActive = currentPath === to;
  return (
    <Link to={to} className={`headbutton ${isActive ? 'active' : ''}`}>
      {text}
    </Link>
  );
}
