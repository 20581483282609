import React, { useContext, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom'; // Import Link from react-router-dom
import { baseurl } from '../App';
import { Usercontext } from '../Usercontext';

export default function Login() {
  const [id, setid] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({});
  const { setuser } = useContext(Usercontext);
  const navigate = useNavigate();

  const validate = () => {
    const newErrors = {};
    if (!id) newErrors.userId = 'User ID is required';
    if (!password) newErrors.password = 'Password is required';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;

    const credentials = { id, password };

    try {
      const response = await fetch(`${baseurl}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(credentials),
      });

      if (response.ok) {
        const data = await response.json();
        setuser(data)
        if (data.id === "bcmha2024000001") {
          navigate('/amain');
        } else {
          navigate('/user');
        }
      } else {
        console.error('Failed to log in');
        setErrors({ login: 'Incorrect Mobile Number or Password' });
      }
    } catch (error) {
      console.error('Error:', error);
      setErrors({ login: 'An error occurred. Please try again.' });
    }
  };

  return (
    <div className='mainpage d-flex mt-5 justify-content-center'>
      <form className='maindivs' onSubmit={handleSubmit}>
        <h2 className='m-2 text-center mb-3'>Login</h2>
        {errors.login && <div className="text-danger text-center mb-2">{errors.login}</div>}
        <div className='form-group'>
          <label className='label label-default mb-2'><strong>Mobile No</strong></label>
          <input
            className='form-control mb-2 mx-auto'
            type='text'
            value={id}
            onChange={(e) => setid(e.target.value)}
            required
          />
          {errors.userId && <div className="text-danger">{errors.userId}</div>}
        </div>
        <div className='form-group'>
          <label className='label label-default mb-2'><strong>Password</strong></label>
          <input
            className='form-control mb-2 mx-auto'
            type='password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          {errors.password && <div className="text-danger">{errors.password}</div>}
        </div>
        <div className="text-center">
          <button className='btn btn-primary w-50 m-3' type='submit'>Log in</button>
        </div>
        <div className="text-center">
          <Link to="/forgot-password" className='text-primary'>Forgot Password?</Link>
        </div>
      </form>
    </div>
  );
}
