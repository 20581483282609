import React from 'react'
import about from '../Assets/about.jpg'
import "../Styles/About.css"
import { IoMdCall } from "react-icons/io";
import { MdEmail } from "react-icons/md";
import dpay from "../Assets/dpay.jpeg"
export default function About() {
  return (
    <div className=''>
    <div className='mainpage about'>
        <img src={about} alt='B ShivSwamy' className=' img-shiv' />
        <div>
            <h3 className='mb-4 justify'>About Us</h3>

            <h5 className='mb-3 justify'>Welcome to the BCM Alumni Network!</h5>

            <p className='fs-5 justify'>Our community is a vibrant tapestry of past residents, each with unique stories and journeys that began within the walls of our beloved hostel. This platform serves as a bridge, connecting alumni from all corners of the globe, fostering lifelong friendships, and celebrating our shared heritage.</p>
        </div>
    </div>
        <div className='afooter'>
            <div className='mt-5 mx-5 mb-3 sinc'>
            <center>
                <h5 className='mb-3'> Contact Us</h5>
                <p><IoMdCall />  97397 19580</p>
                <p><MdEmail /> bcmhostelalumni@gmail.com</p>
            </center>
            </div>
            <div className='mt-5 mx-5 mb-3 sinc'>
                <center>
                <h5 className='mb-3'>&copy; Since 2023</h5>
                <p>Founded By B Shiv Swamy</p><br/><br/><br/><br/>
                <p>Rg. No :-DRMY/SOR/810/2023-2024</p>
                </center>
            </div>
            <div className='m-2'>
            <center>
                <h5>Give Donation For the Association</h5>
                <img src={dpay} alt='qr code' className='qr'/>
            </center>
            </div>
        </div>
    </div>
  )
}
