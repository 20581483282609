import React, { useState } from 'react';
import { baseurl } from '../App';
import { toast } from 'react-toastify';
import dpay from "../Assets/dpay.jpeg";

export default function Enrollment() {
  const [name, setName] = useState('');
  const [dob, setDob] = useState('');
  const [phone, setphone] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errors, setErrors] = useState({});
  const [transactionId, setTransactionId] = useState('');
  const [payment, setpayment] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const amount = 1000;

  const validate = () => {
    const newErrors = {};
    if (!name) newErrors.name = 'Name is required';
    if (!dob) newErrors.dob = 'Date of Birth is required';
    if (!phone) newErrors.mobile = 'Mobile No is required';
    if (!password) newErrors.password = 'Password is required';
    if (password !== confirmPassword) newErrors.confirmPassword = 'Passwords do not match';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;
    if (payment === false) {
      alert("Payment not done");
      return;
    }
    const user = { name, dob, phone, password, status: "pending",transactionId:transactionId,otp:0};

    try {
      const response = await fetch(`${baseurl}/postusers`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(user),
      });

      if (response.ok) {
        const data = await response.json();
        toast.success('Registered successfully');
        alert(`Id: ${data.id} Password: ${data.password}`);
        setpayment(false);
        setName("");
        setConfirmPassword("");
        setDob("");
        setPassword("");
        setphone("");
      } else {
        toast.error('Failed to register user');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handlePaymentSuccess = () => {
    if (transactionId) {
      toast.success('Payment Successful');
      setpayment(true);
      setShowModal(false);
    } else {
      toast.error('Please enter the transaction ID');
    }
  };

  const handlePaymentFailure = () => {
    toast.error('Payment Failed');
    setShowModal(false);
  };

  return (
    <div className='mainpage d-flex mt-5 justify-content-center'>
      <form className='maindivs'>
        <h2 className='m-2 text-center mb-3'>Registration Form</h2>
        <div className='form-group'>
          <label className='label label-default mb-2'><strong>Name</strong></label>
          <input
            className='form-control mb-2 mx-auto'
            type='text'
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          {errors.name && <div className="text-danger">{errors.name}</div>}
        </div>
        <div className='form-group'>
          <label className='label label-default mb-2'><strong>Date of Birth</strong></label>
          <input
            className='form-control mb-2 mx-auto'
            type='date'
            value={dob}
            onChange={(e) => setDob(e.target.value)}
            required
          />
          {errors.dob && <div className="text-danger">{errors.dob}</div>}
        </div>
        <div className='form-group'>
          <label className='label label-default mb-2'><strong>Mobile No</strong></label>
          <input
            className='form-control mb-2 mx-auto'
            type='tel'
            value={phone}
            onChange={(e) => setphone(e.target.value)}
            required
          />
          {errors.mobile && <div className="text-danger">{errors.mobile}</div>}
        </div>
        <div className='form-group'>
          <label className='label label-default mb-2'><strong>Password</strong></label>
          <input
            className='form-control mb-2 mx-auto'
            type='password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          {errors.password && <div className="text-danger">{errors.password}</div>}
        </div>
        <div className='form-group'>
          <label className='label label-default mb-2'><strong>Confirm Password</strong></label>
          <input
            className='form-control mb-2 mx-auto'
            type='password'
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
          {errors.confirmPassword && <div className="text-danger">{errors.confirmPassword}</div>}
        </div>
        <div className="text-center row">
          <button
            className='btn btn-warning w-50 m-3 col'
            type='button'
            onClick={() => setShowModal(true)}
          >
            Pay Enrollment Fee
          </button>
          <button className='btn btn-primary w-50 m-3 col' type='submit' onClick={handleSubmit}>
            Register
          </button>
        </div>
      </form>

      {showModal && (
        <>
          <div className="modal-backdrop-custom"></div>
          <div className="modal show d-block modal-custom" tabIndex="-1" role="dialog">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Payment Details</h5>
                  <button type="button" className="close" onClick={() => setShowModal(false)} aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body text-center">
                  <p>Name: {name}</p>
                  <p>Fee: ₹{amount}</p>
                  <img src={dpay} alt="QR Code" style={{ width: '200px' }} />
                  <div className="form-group mt-3 d-flex justify-content-center gap-3">
                    <label htmlFor="transactionId"><strong>Enter Transaction ID</strong></label>
                    <input
                      className='form-control w-50'
                      type='text'
                      id='transactionId'
                      value={transactionId}
                      onChange={(e) => setTransactionId(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <div className="modal-footer d-flex justify-content-center align-items-center">
                  <button type="button" className="btn btn-success" onClick={handlePaymentSuccess}>
                    Payment Successful
                  </button>
                  <button type="button" className="btn btn-danger" onClick={handlePaymentFailure}>
                    Payment Failed
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
