import React, { useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Dmain from './Dashboard/Dmain';
import Enroolment from './Dashboard/Enroolment';
import Homepage from './Dashboard/Homepage';
import Gallery from './Dashboard/Gallery';
import Login from './Dashboard/Login';
import Umain from './User/Umain';
import Idgenerator from './User/Idgenerator';
import Profile from './User/Profile';
import Amain from './Admin/Amain';
import Managegallery from './Admin/Managegallery';
import Manageusers from './Admin/Manageusers';
import Managenews from './Admin/Managenews';
import { Usercontext } from './Usercontext';
import Updatepassword from './Admin/Updatepassword';
import Committe from './Dashboard/Committe';
import About from './Dashboard/About';
import ApproveUsers from './Admin/ApproveUsers';
import ForgotPassword from './Dashboard/ForgotPassword';

export const baseurl=  "https://divine-sparkle-production.up.railway.app"


function App() {
  const [user, setuser] = useState(()=>{
    try{
      const item =window.localStorage.getItem('user');
      return item ? JSON.parse(item) :{}
    }catch(error){
      console.log(error);
      return {}
    }
  });

  useEffect(() => {
    localStorage.setItem('user',JSON.stringify(user))
  },[user]);
  return (
    <Usercontext.Provider value={{user,setuser}}>
    <BrowserRouter>
      <Routes>

        <Route path='' element={<Dmain />} >
          <Route index element={<Homepage/>}/>
          <Route path='enroolment' element={<Enroolment />} />
          <Route path='gallery' element={<Gallery/>} />
          <Route path='login' element={<Login />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path='Committe' element={<Committe/>}/>
          <Route path='about' element={<About/>}/>
        </Route>

        <Route path='user' element={<Umain/>}>
          <Route index element={<Homepage/>}/>
          <Route path='id' element={<Idgenerator/>}/>
          <Route path='profile' element={<Profile/>}/>
          <Route path='enroolment' element={<Enroolment />} />
          <Route path='gallery' element={<Gallery/>} />
          <Route path='Committe' element={<Committe/>}/>
          <Route path='about' element={<About/>}/>
        </Route>

        <Route path='amain' element={<Amain/>}>
          <Route index element={<Manageusers/>}/>
          <Route path='mgallery' element={<Managegallery/>}/>
          <Route path='mnews' element={<Managenews/>}/>
          <Route path='updatepassword' element={<Updatepassword/>}/>
          <Route path='approve' element={<ApproveUsers/>}/>
          <Route path='Committe' element={<Committe/>}/>
        </Route>
      </Routes>
    </BrowserRouter>
    </Usercontext.Provider>
  );
}

export default App;

