import React, { useState, useEffect } from 'react';
import '../Styles/Gallery.css';
import axios from 'axios';
import { baseurl } from '../App';

export default function Gallery() {
  const [gallery, setGallery] = useState([]);
  const [loading, setLoading] = useState(true); // State to track loading status

  useEffect(() => {
    fetchGallery();
  }, []);

  const fetchGallery = async () => {
    try {
      const response = await axios.get(`${baseurl}/getgallery`);
      setGallery(response.data);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false); // Stop loading after fetching data
    }
  };

  return (
    <section className="gallery mainpage">
      {loading ? (
        <div className="loader m-5"></div> 
      ) : (
        <ul className="images">
          {gallery.map((item) => (
            <li key={item.id}>
              <div className="container">
                <img src={item.image} alt="Avatar" className="card photo" />
                <div className="overlay">
                  <div className="text">{item.discription}</div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      )}
    </section>
  );
}
