import React, { useEffect, useState } from 'react'
import '../Styles/Manage.css'
import { baseurl } from '../App';
import axios from 'axios';
import { toast } from 'react-toastify';
export default function Managegallery() {
  const [gallryimages, setgallryimages] = useState([]);
  const [discription, setdiscription] = useState("");
  const [id, setid] = useState();
  const [image, setimage] = useState("");
  const [edit, setedit] = useState(false);

  const handledelete = () => {
    axios
     .delete(baseurl + `/deletegallery/${id}`)
     .then((res) => {
        toast.success(res.data);
        fetchgallery();
      })
     .catch((error) => {
        console.log(error);
      });
  }

  const handleSubmit = () => {
    if (edit) {
      axios
        .put(baseurl + `/putgallery`, { id, discription,image})
        .then((res) => {
          toast.success(res.data);
          fetchgallery();
          clearAll();
          setedit(false);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      axios
        .post(baseurl + `/postgallery`, { discription, image })
        .then((res) => {
          toast.success(res.data);
          fetchgallery();
          clearAll();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const clearAll = () => {
    setdiscription("");
    setimage("");
  };

  const handelImage = (e) => {
    let file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setimage(reader.result);
    };
  };
  useEffect(() => {
    fetchgallery();
  }, []);

  const fetchgallery = () => {
    axios.get(baseurl + `/getgallery`)
      .then((response) => {
        setgallryimages(response.data);
      })
      .catch((err) => console.log(err));
  };
  return (
         <div className='news-main'>
      <form className='card m-3 w-25 p-3'>
        <h5 className='mb-2 text-center'>Add Image</h5>
        <input type="text" className="form-control mb-2" value={discription} onChange={(e)=>{setdiscription(e.target.value)}} placeholder="Title"/>
        <input type='file' className='form-control mb-2' onChange={handelImage}/>
        <button type='submit' className='btn btn-primary mb-2' onClick={()=>{handleSubmit()}}>{edit ? "Edit":"Add"}</button>
      </form>
      {gallryimages.map((item)=>{return(
      <div className='gallery-image card m-3 w-25 p-3 '>
        <h4 className='text-center'>{item.discription}</h4>
        <img className='width-image' src={item.image} alt="imag"/>
        <div className='row'>
        <button className='col w-50 m-2 btn btn-warning mb-2' onClick={()=>{
          setedit(true);
          setid(item.id);
          setdiscription(item.discription);
          setimage(item.image);
        }}>Edit</button>
        <buton type="submit" className='col m-2 w-50 btn btn-danger' onClick={()=>{setid(item.id);handledelete()}}>Delete</buton>
        </div>
      </div>)})}
    </div>
  )
}
